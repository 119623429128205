const axios = require("axios");
class DataServiceClass {
  users = null;
  authToken = sessionStorage.getItem("authToken") || null;

  getAuthToken() {
    return this.authToken;
  }

  login(pinLogin) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/pipedrive/login`, { pin: pinLogin })
        .then((response) => {
          console.log(response);
          this.authToken = response.data;
          sessionStorage.setItem("authToken", this.authToken);
          resolve(response);
        })
        .catch((error) => {
          // handle error
          console.log(error);
          // this.authToken = "TOKEN";
          // sessionStorage.setItem("authToken", this.authToken);
          // resolve(this.authToken);
          reject(error);
        });
    });
  }

  queryUsers() {
    return new Promise((resolve, reject) => {
      if (this.users != null) {
        resolve(this.users);
      }
      axios
        .get(`/pipedrive/data?pin=${this.authToken}`)
        .then((response) => {
          console.log(response.data.data);
          this.users = response.data.data;
          resolve(this.users);
        })
        .catch((error) => {
          // handle error
          console.log(error);
        });
    });
  }

  checkUserExistance(name) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/pipedrive/checkUsers?toFind=${encodeURIComponent(name)}&pin=${
            this.authToken
          }`
        )
        .then((response) => {
          console.log(response);
          resolve(response.data.data.items.map((item) => item.item));
        })
        .catch((error) => {
          // handle error
          console.log(error);
          resolve([]);
        });
    });
  }
  createDocument(values) {
    return new Promise((resolve, reject) => {
      console.log("document creation", values);
      const title =
        values.gender === "w"
          ? "Frau"
          : values.gender === "m"
          ? "Herr"
          : undefined; //"Herr";
      const city = values.city ? values.city : undefined;
      const plz = values.postalcode ? values.postalcode : undefined;
      const street = values.street ? values.street : undefined;
      const surname = values.firstname ? values.firstname : undefined;
      const lastname = values.lastname ? values.lastname : undefined;
      axios
        .post(
          `/doc/generate`,
          { title, city, plz, street, surname, lastname },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          console.log(response);
          var fileName = `Anschreiben_${lastname}.docx`;

          const a = document.createElement("a");
          a.setAttribute("download", fileName);
          a.setAttribute("href", window.URL.createObjectURL(response.data));
          a.click();
          resolve(response);
        })
        .catch((error) => {
          // handle error
          console.log(error);
          // this.authToken = "TOKEN";
          // sessionStorage.setItem("authToken", this.authToken);
          // resolve(this.authToken);
          reject(error);
        });
    });
  }
  getPersonData = (values) => {
    return {
      name: `${
        values.firstname
          ? `${values.firstname} `
          : `${
              values.gender
                ? values.gender === "m"
                  ? "Herr"
                  : values.gender === "w"
                  ? "Frau"
                  : ""
                : ""
            }`
      } ${values.lastname}`,
      email: values.email,
      phone: values.mobilenumber ? [values.mobilenumber] : undefined,
    };
  };
  getDealData = (values) => {
    const personData = this.getPersonData(values);
    return {
      user_id: values.assignToUser,
      note: ``,
      title: personData.name,
    };
  };
  createWithOldUser = (values, person) => {
    const body = {
      pin: this.authToken,
      person_id: person.id,
      ...this.getDealData(values),
      ...this.generateNote(values),
    };

    Object.entries(body).forEach(
      ([key, value]) => value === "" && delete body[key]
    );

    return this.submitData(`/pipedrive/createDealWithOldUser`, body);
  };
  createWithNewUser = (values) => {
    const body = {
      pin: this.authToken,
      ...this.getPersonData(values),
      ...this.getDealData(values),
      ...this.generateNote(values),
    };
    Object.entries(body).forEach(
      ([key, value]) => value === "" && delete body[key]
    );

    return this.submitData(`/pipedrive/createDealWithNewUser`, body);
  };
  generateNote = (values) => {
    let note = "";
    if (values.note) {
      if (values.note.indexOf("Weitere Daten zum Interessenten;") !== -1) {
        const splitted = values.note.split("Weitere Daten zum Interessenten;");
        note = `<p>${
          splitted[0]
        }</p></br><h3>Weitere Daten zum Interessenten</h3>${splitted[1]
          .split("; ")
          .map((entry) => `<p>${entry}</p>`)
          .join("")}`;
      } else {
        note = `<p>${values.note}</p>`;
      }
    } else {
      note = "<p>-</p>";
    }

    return {
      note: `
      <h3>Personendaten</h3>
      ${
        values.gender
          ? `<p>Geschlecht: ${
              values.gender === "m"
                ? "Herr"
                : values.gender === "w"
                ? "Frau"
                : "-"
            }</p>`
          : ""
      }
      ${values.firstname ? `<p>Vorname: ${values.firstname}</p>` : ""}
      ${values.lastname ? `<p>Nachname: ${values.lastname}</p>` : ""}
      ${values.street ? `<p>Straße/Hausnummer: ${values.street}</p>` : ""}
      ${values.postalcode ? `<p>Postleitzahl: ${values.postalcode}</p>` : ""}
      ${values.city ? `<p>Stadt: ${values.city}</p>` : ""}
      ${values.email ? `<p>E-Mail: ${values.email}</p>` : ""}
      ${values.mobilenumber ? `<p>Mobil: ${values.mobilenumber}</p>` : ""}
      ${
        note
          ? ` 
      </br><h3>Anfrage</h3>
      <p>${note}</p>
      </br>
      `
          : ""
      }
      </br><h3>Objekt-Daten</h3>
      <h4>${values.objectName}</h4> 
      <p>
      ${values.objectStreet ? values.objectStreet : ""} | ${
        values.objectCity ? values.objectCity : ""
      } | ${values.objectDistrict ? values.objectDistrict : ""} | ${
        values.objectCountry ? values.objectCountry : ""
      } 
      </p>
      <p>
      ${
        values.objectPrice
          ? values.objectPrice +
            (values.objectPrice.indexOf("€") === -1 ? " €" : "")
          : ""
      } | ${values.objectArea ? values.objectArea + " qm" : ""}
           </p>
        ${
          values.objectUrl
            ? `<a href="${values.objectUrl}">${values.objectUrl}</a> `
            : ""
        }
      `,
    };
  };
  submitData(url, body) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, body)
        .then((response) => {
          console.log(response);
          resolve();
        })
        .catch((error) => {
          // handle error
          console.log(error);
          reject();
        });
    });
  }
}

const DataService = new DataServiceClass();
export default DataService;
