import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import "./PipelineForm.scss";
import { createNumberMask } from "text-mask-addons";
import MaskedInput from "react-text-mask";
import {
  Avatar,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Input,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  OutlinedInput,
  Snackbar,
} from "@material-ui/core";
import { Field, Form } from "react-final-form";
import DataService from "../services/DataService";
import { Alert } from "@material-ui/lab";

function CurrencyMaskedInput(props) {
  const { inputRef, ...other } = props;

  const defaultMaskOptions = {
    prefix: "",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ".",
    allowDecimal: true,
    decimalSymbol: ",",
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={currencyMask}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

const requiredValidator = (value) =>
  !value || value.length === 0 ? "Pflichtfeld" : null;
const validate = (values) => {
  const errors = {};

  return errors;
};

const SubmitModal = ({ values, onClose, onSuccess, onError }) => {
  const [usersToAssign, setUsersToAssign] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  useEffect(() => {
    DataService.checkUserExistance(values.lastname)
      .then((users) => {
        setUsersToAssign(users);
        if (users.length === 0) {
          submitData();
        }
      })
      .catch((err) => {
        setUsersToAssign([]);
        submitData();
      });
  }, []);

  const submitData = (person) => {
    setSubmitting(true);
    const submitPromise = person
      ? DataService.createWithOldUser
      : DataService.createWithNewUser;

    submitPromise(values, person)
      .then(() => {
        setSubmitSuccess(true);
        // onSuccess();
      })
      .catch((err) => {
        onError();
        onClose(false);
      });
  };
  return (
    <Dialog onClose={() => onClose(false)} open={values !== null}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorSnackbar}
        autoHideDuration={4000}
        onClose={() => setErrorSnackbar(false)}
      >
        <Alert severity="error">
          Dokument konnte leider nicht erstellt werden.
        </Alert>
      </Snackbar>

      {submitSuccess && (
        <>
          <DialogContent className="create-document-dialog">
            <Typography variant="subtitle1" gutterBottom>
              Wollen Sie noch ein Anschreiben für den Lead erstellen?
            </Typography>
            <div className="actions">
              <Button
                type="button"
                variant="contained"
                onClick={() => {
                  onSuccess();
                  onClose(true);
                }}
              >
                Nein
              </Button>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={() => {
                  DataService.createDocument(values)
                    .then(() => {
                      onSuccess();
                      onClose(true);
                    })
                    .catch((err) => {
                      setErrorSnackbar(true);
                      onSuccess();
                      onClose(true);
                    });
                }}
              >
                Dokument erstellen
              </Button>
            </div>
          </DialogContent>
        </>
      )}
      {!submitSuccess && !submitting && usersToAssign == null && (
        <div className="loading-container">
          <CircularProgress />
          <Typography variant="overline" display="block">
            Suche nach ähnlichen Benutzern...
          </Typography>
        </div>
      )}
      {!submitSuccess && submitting && (
        <div className="loading-container">
          <CircularProgress />
          <Typography variant="overline" display="block">
            Erstelle den Lead...
          </Typography>
        </div>
      )}
      {!submitSuccess && !submitting && usersToAssign && (
        <>
          <DialogTitle>Personen zuordnen</DialogTitle>
          <DialogContent>
            <Typography variant="subtitle1" gutterBottom>
              Wir haben folgende Personen gefunden, die zu den von Ihnen
              angegebenen Daten passen könnten:
            </Typography>
            <List>
              {usersToAssign.map((user) => {
                let secondaryText = "";
                (user.emails || []).forEach(
                  (email) => (secondaryText += `${email} `)
                );
                (user.phones || []).forEach(
                  (phone) => (secondaryText += `${phone} `)
                );

                return (
                  <ListItem
                    button
                    onClick={() => submitData(user)}
                    key={user.id}
                  >
                    <ListItemAvatar>
                      <Avatar className="user-avatar">
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={user.name}
                      secondary={secondaryText ? secondaryText : undefined}
                    />
                  </ListItem>
                );
              })}

              <ListItem autoFocus button onClick={() => submitData()}>
                <ListItemAvatar>
                  <Avatar>
                    <AddIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Neue Person anlegen" />
              </ListItem>
            </List>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

let formRef;
const PipelineForm = ({ pipedriveUsers, registerFormRef }) => {
  const [submittingValues, setSubmittingValues] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorSnackbar1, setErrorSnackbar1] = useState(false);
  const [errorSnackbar2, setErrorSnackbar2] = useState(false);

  const onSubmit = (values) => {
    setSubmittingValues(values);
  };
  const onDocumentCreation = (values) => {
    DataService.createDocument(values)
      .then(() => {})
      .catch((err) => {
        setErrorSnackbar2(true);
      });
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorSnackbar1}
        autoHideDuration={4000}
        onClose={() => setErrorSnackbar1(false)}
      >
        <Alert severity="error">Deal konnte nicht erstellt werden</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorSnackbar2}
        autoHideDuration={4000}
        onClose={() => setErrorSnackbar2(false)}
      >
        <Alert severity="error">
          Dokument konnte nicht erstellt werden. Bitte überprüfen Sie Ihre
          Eingaben und versuchen es erneut.
        </Alert>
      </Snackbar>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, form }) => {
          if (formRef !== form) {
            formRef = form;
            registerFormRef(form);
          }

          return (
            <form onSubmit={handleSubmit}>
              {submittingValues && (
                <SubmitModal
                  values={submittingValues}
                  onSuccess={() => setShowSuccess(true)}
                  onError={() => setErrorSnackbar1(true)}
                  onClose={(resetForm) => {
                    setSubmittingValues(null);
                    if (resetForm) {
                      form.restart();
                    }
                  }}
                />
              )}

              <div className="form-page">
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={showSuccess}
                  autoHideDuration={6000}
                  onClose={() => setShowSuccess(false)}
                >
                  <Alert severity="success">Lead erfolgreich erstellt</Alert>
                </Snackbar>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid
                    container
                    item
                    spacing={2}
                    direction="row"
                    alignItems="flex-start"
                    lg={6}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Daten zum Interessenten
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <Field
                        name="gender"
                        render={({ input, meta }) => (
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="gender-label">Anrede</InputLabel>
                            <Select
                              error={meta.touched && meta.error}
                              helperText={meta.touched && meta.error}
                              labelId="gender-label"
                              // value={"m"}
                              // onChange={() => {}}
                              label="Anrede"
                              {...input}
                            >
                              <MenuItem value={"m"}>Herr</MenuItem>
                              <MenuItem value={"w"}>Frau</MenuItem>
                              <MenuItem value={"d"}>-</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        name="firstname"
                        render={({ input, meta }) => (
                          <TextField
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                            fullWidth
                            label="Vorname"
                            variant="outlined"
                            {...input}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <Field
                        validate={requiredValidator}
                        name="lastname"
                        render={({ input, meta }) => (
                          <TextField
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                            fullWidth
                            label="Nachname *"
                            variant="outlined"
                            {...input}
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        name="street"
                        render={({ input, meta }) => (
                          <TextField
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                            fullWidth
                            label="Straße/Hausnummer"
                            variant="outlined"
                            {...input}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Field
                        name="postalcode"
                        render={({ input, meta }) => (
                          <TextField
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                            fullWidth
                            label="PLZ"
                            variant="outlined"
                            {...input}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        name="city"
                        render={({ input, meta }) => (
                          <TextField
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                            fullWidth
                            label="Ort"
                            variant="outlined"
                            {...input}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="email"
                        render={({ input, meta }) => (
                          <TextField
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                            fullWidth
                            label="E-Mail"
                            variant="outlined"
                            {...input}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="mobilenumber"
                        render={({ input, meta }) => (
                          <TextField
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                            fullWidth
                            label="Telefonnummer"
                            variant="outlined"
                            {...input}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        name="note"
                        render={({ input, meta }) => (
                          <TextField
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                            fullWidth
                            multiline
                            rows={5}
                            label="Anfrage-Notiz"
                            variant="outlined"
                            {...input}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    spacing={2}
                    direction="row"
                    alignItems="flex-start"
                    lg={6}
                  >
                    <Grid item xs={12}>
                      <Typography variant="h6">Daten zum Objekt</Typography>
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Field
                        validate={requiredValidator}
                        name="objectName"
                        render={({ input, meta }) => (
                          <TextField
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                            fullWidth
                            label="Objekt-Bezeichnung *"
                            variant="outlined"
                            {...input}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="objectStreet"
                        render={({ input, meta }) => (
                          <TextField
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                            fullWidth
                            label="Straße"
                            variant="outlined"
                            {...input}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="objectCity"
                        render={({ input, meta }) => (
                          <TextField
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                            fullWidth
                            label="Ort"
                            variant="outlined"
                            {...input}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="objectDistrict"
                        render={({ input, meta }) => (
                          <TextField
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                            fullWidth
                            label="Stadtbezirk"
                            variant="outlined"
                            {...input}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="objectCountry"
                        render={({ input, meta }) => (
                          <TextField
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                            fullWidth
                            label="Land"
                            variant="outlined"
                            {...input}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="objectPrice"
                        render={({ input, meta }) => (
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="price-input">Preis</InputLabel>
                            <OutlinedInput
                              error={meta.touched && meta.error}
                              helperText={meta.touched && meta.error}
                              name="textmask"
                              id="price-input"
                              inputComponent={CurrencyMaskedInput}
                              endAdornment={
                                <InputAdornment position="end">
                                  €
                                </InputAdornment>
                              }
                              labelWidth={40}
                              {...input}
                            />
                          </FormControl>
                        )}
                      />

                      {/* <CurrencyMaskedInput
              fullWidth
              
              label="Preis"
              variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
            /> */}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="objectArea"
                        render={({ input, meta }) => (
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel htmlFor="area-input">Fläche</InputLabel>
                            <OutlinedInput
                              error={meta.touched && meta.error}
                              helperText={meta.touched && meta.error}
                              name="textmask"
                              id="area-input"
                              inputComponent={CurrencyMaskedInput}
                              endAdornment={
                                <InputAdornment position="end">
                                  m²
                                </InputAdornment>
                              }
                              labelWidth={50}
                              {...input}
                            />
                          </FormControl>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Field
                        name="objectUrl"
                        render={({ input, meta }) => (
                          <TextField
                            error={meta.touched && meta.error}
                            helperText={meta.touched && meta.error}
                            fullWidth
                            label="Expose-Url"
                            variant="outlined"
                            {...input}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <div className="action-row">
                      <Button
                        onClick={() => form.restart()}
                        variant="contained"
                      >
                        Zurücksetzen
                      </Button>

                      <div className="fill" />
                      <Button
                        className="create-document-button"
                        type="button"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          onDocumentCreation(form.getState().values);
                        }}
                      >
                        Dokument erstellen
                      </Button>
                      <Field
                        name="assignToUser"
                        validate={requiredValidator}
                        render={({ input, meta }) => (
                          <FormControl
                            variant="outlined"
                            className="assign-user"
                          >
                            <InputLabel id="user-label">
                              Zugewiesener Nutzer *
                            </InputLabel>
                            <Select
                              error={meta.touched && meta.error}
                              helperText={meta.touched && meta.error}
                              labelId="user-label"
                              label="Zugewiesener Nutzer"
                              {...input}
                            >
                              {pipedriveUsers.map((user) => (
                                <MenuItem key={user.id} value={user.id}>
                                  {user.name}
                                </MenuItem>
                              ))}
                            </Select>
                            {meta.touched && meta.error && (
                              <FormHelperText error>
                                {meta.error}
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      />

                      <Button type="submit" variant="contained" color="primary">
                        Lead zuweisen
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </form>
          );
        }}
      />
    </>
  );
};

export default PipelineForm;
