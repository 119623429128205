import React, { useRef, useState } from "react";
import "./LoginView.scss";
import ReactPinField from "react-pin-field";
import DataService from "../services/DataService";
import Card from "@material-ui/core/Card";
import {
  AppBar,
  Backdrop,
  CardContent,
  CircularProgress,
  Snackbar,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LoginView = ({ onLogin }) => {
  const pinref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);

  const loginFc = (pin) => {
    setLoading(true);
    DataService.login(pin)
      .then((authToken) => {
        setLoading(false);
        onLogin(authToken);
      })
      .catch((err) => {
        console.log("pinref", pinref);
        pinref.current.forEach((input) => (input.value = ""));
        pinref.current[0].focus();
        setErrorSnackbar(true);
        setLoading(false);
      });
  };
  return (
    <div className="login-view">
      <Card className={"login-card"} variant="outlined">
        <AppBar position="static">
          <div className="title">Pipedrive Importer</div>
        </AppBar>
        <CardContent>
          <Typography variant="h5" component="h2">
            Login
          </Typography>
          <Typography color="textSecondary">
            Geben Sie den Ihnen bekannten PIN für den Login ein
          </Typography>
          <div className="pin-field-container">
            <ReactPinField
              className={`pin-field`}
              type={"password"}
              validate={/^[0-9]$/}
              ref={pinref}
              onComplete={loginFc}
              length={5}
            />
          </div>
        </CardContent>
      </Card>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={errorSnackbar}
        autoHideDuration={2000}
        onClose={() => setErrorSnackbar(false)}
      >
        <Alert severity="error">Pin fehlerhaft</Alert>
      </Snackbar>
      <Backdrop open={loading} className={"loading-backdrop"}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
export default LoginView;
