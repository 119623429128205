import "./App.scss";
import { useEffect, useState } from "react";
import DataService from "./services/DataService";
import FormView from "./components/FormView";
import LoginView from "./components/LoginView";
function App() {
  const [authToken, setAuthToken] = useState(DataService.getAuthToken());
  useEffect(() => {
    // setAuthToken(DataService.getAuthToken());
    // DataService.queryUsers();
  }, []);
  return authToken ? (
    <FormView />
  ) : (
    <LoginView onLogin={(authToken) => setAuthToken(authToken)} />
  );
}

export default App;
