import React, { useCallback } from "react";
import "./FormView.scss";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import PipelineForm from "./PipelineForm";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useEffect, useState } from "react";
import { AppBar, Snackbar } from "@material-ui/core";
import DataService from "../services/DataService";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PublishIcon from "@material-ui/icons/Publish";
import vCard from "vcf";
import { Alert } from "@material-ui/lab";

const SELECTORS = [
  {
    field: "objectName",
    openimmo: {
      selector: "openimmo_feedback objekt bezeichnung",
    },
    immoscout: {
      selector: "objektanfrage objekt o_stichwort",
    },
    vcfImport: (vcard) => "Kontakt von E-Büro",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt strasse",
    },
    immoscout: {
      selector: "objektanfrage objekt o_strasse",
    },
    field: "objectStreet",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt ort",
    },
    immoscout: {
      selector: "objektanfrage objekt o_ort",
    },
    field: "objectCity",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt land",
    },
    immoscout: {
      selector: "objektanfrage objekt o_land",
    },
    field: "objectCountry",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt stadtbezirk",
    },
    immoscout: {
      selector: "objektanfrage objekt o_stadtbezirk",
    },
    field: "objectDistrict",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt expose_url",
    },
    immoscout: {
      selector: "objektanfrage objekt o_link",
    },
    field: "objectUrl",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt preis",
      transform: (value) => value,
    },
    immoscout: {
      selector: "objektanfrage objekt o_preis",
      transform: (value) => value,
    },
    field: "objectPrice",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt flaeche",
      transform: (value) => value,
    },
    immoscout: {
      selector: "objektanfrage objekt o_flaeche",
      transform: (value) => value,
    },
    field: "objectArea",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt interessent anrede",
      transform: (value) =>
        value
          ? value.toLowerCase() === "frau"
            ? "w"
            : value.toLowerCase() === "herr"
            ? "m"
            : "d"
          : "d",
    },
    immoscout: {
      selector: "objektanfrage interessent i_anrede",
      transform: (value) =>
        value
          ? value.toLowerCase() === "frau"
            ? "w"
            : value.toLowerCase() === "herr"
            ? "m"
            : "d"
          : "d",
    },
    field: "gender",
    vcfImport: (vcard) =>
      vcard.data.n &&
      vcard.data.n._data.split(";").length > 2 &&
      vcard.data.n._data.split(";")[3]
        ? vcard.data.n._data.split(";")[3].toLowerCase() === "herr"
          ? "m"
          : vcard.data.n._data.split(";")[3].toLowerCase() === "frau"
          ? "w"
          : "d"
        : "d",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt interessent vorname",
    },
    immoscout: {
      selector: "objektanfrage interessent i_vorname",
    },
    field: "firstname",
    vcfImport: (vcard) =>
      vcard.data.n &&
      vcard.data.n._data.split(";").length > 1 &&
      vcard.data.n._data.split(";")[1]
        ? vcard.data.n._data.split(";")[1]
        : "",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt interessent nachname",
    },
    immoscout: {
      selector: "objektanfrage interessent i_name",
    },
    field: "lastname",
    vcfImport: (vcard) =>
      vcard.data.n && vcard.data.n._data.split(";")[0]
        ? vcard.data.n._data.split(";")[0]
        : "",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt interessent strasse",
    },
    immoscout: {
      selector: "objektanfrage interessent i_strasse",
    },
    field: "street",
    vcfImport: (vcard) =>
      vcard.data.adr &&
      vcard.data.adr._data.split(";") > 2 &&
      vcard.data.adr._data.split(";")[2]
        ? vcard.data.adr._data.split(";")[2]
        : "",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt interessent plz",
    },
    immoscout: {
      selector: "objektanfrage interessent i_plz",
    },
    field: "postalcode",
    vcfImport: (vcard) =>
      vcard.data.adr &&
      vcard.data.adr._data.split(";") > 5 &&
      vcard.data.adr._data.split(";")[5]
        ? vcard.data.adr._data.split(";")[5]
        : "",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt interessent ort",
    },
    immoscout: {
      selector: "objektanfrage interessent i_ort",
    },
    field: "city",
    vcfImport: (vcard) =>
      vcard.data.adr &&
      vcard.data.adr._data.split(";") > 3 &&
      vcard.data.adr._data.split(";")[3]
        ? vcard.data.adr._data.split(";")[3]
        : "",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt interessent tel",
    },
    immoscout: {
      selector: "objektanfrage interessent i_tel",
    },
    field: "mobilenumber",
    vcfImport: (vcard) =>
      vcard.data.tel && vcard.data.tel._data ? vcard.data.tel._data : "",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt interessent email",
    },
    immoscout: {
      selector: "objektanfrage interessent i_email",
    },
    field: "email",
    vcfImport: (vcard) =>
      vcard.data.email && vcard.data.email._data ? vcard.data.email._data : "",
  },
  {
    openimmo: {
      selector: "openimmo_feedback objekt interessent anfrage",
    },
    immoscout: {
      selector: "objektanfrage interessent i_info",
    },
    field: "note",
    vcfImport: (vcard) =>
      vcard.data.note && vcard.data.note._data ? vcard.data.note._data : "",
  },
];
const gatherDataFromXML = (xmlString) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(xmlString, "application/xml");

  let root = dom.querySelector("openimmo_feedback");
  let xmlType = "openimmo";

  if (!root) {
    root = dom.querySelector("objektanfrage");
    xmlType = "immoscout";
    if (!root) {
      console.log("Not a valid xml", xmlString);
      return gatherDataFromVCF(xmlString);
    }
  }
  const values = {};

  SELECTORS.forEach(({ [xmlType]: { selector, transform }, field }) => {
    const node = dom.querySelector(selector);
    if (node) {
      let value = node.innerHTML;
      if (transform) {
        value = transform(value);
      }
      values[field] = value;

      try {
        values[field] = decodeURIComponent(escape(value));
      } catch (e) {
        values[field] = value;
      }
    } else {
      values[field] = null;
    }
  });

  console.log("xml", values);
  return values;
};
const gatherDataFromVCF = (dataString, isSecondTry) => {
  try {
    const values = {};
    var card = new vCard().parse(dataString);
    console.log(card);
    SELECTORS.forEach(({ field, vcfImport }) => {
      if (vcfImport) {
        values[field] = vcfImport(card);
      }
    });

    return values;
  } catch (err) {
    console.log("Not a valid vcf", dataString, err);
    if (isSecondTry) {
      return null;
    } else {
      const fixedString = dataString.replaceAll("\n", "\r\n");
      return gatherDataFromVCF(fixedString, true);
    }
  }
};

let formRef;
function FormView() {
  const [pipedriveUsers, setPipedriveUsers] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  useEffect(() => {
    // setAuthToken(DataService.getAuthToken);
    DataService.queryUsers()
      .then((users) => {
        setPipedriveUsers(users);
      })
      .catch((err) => {});
  }, []);

  const onDrop = useCallback((acceptedFiles) => {
    console.log("accepted files", acceptedFiles);
    if (acceptedFiles && acceptedFiles.length === 1) {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;
        const obj = gatherDataFromXML(binaryStr);
        if (obj) {
          // formRef.form.
          formRef.reset();
          Object.entries(obj).map(([key, value]) => formRef.change(key, value));
        } else {
          setShowErrorMessage(true);
        }
        console.log("read file", obj);
      };
      reader.readAsBinaryString(acceptedFiles[0], "UTF-8");
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    noClick: true,
  });

  return (
    <div className="FormView" {...getRootProps()}>
      <Snackbar
        open={showErrorMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={() => setShowErrorMessage(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Die Datei hat kein gültiges Dateiformat.
        </Alert>
      </Snackbar>
      <AppBar position="static" className="app-header">
        <Toolbar className="app-header-toolbar">
          <Typography variant="h6" className="app-title">
            Pipedrive-Lead-Importer
          </Typography>
          <IconButton onClick={open} color="inherit">
            <PublishIcon />
          </IconButton>
          {/* <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => {}}
            color="inherit"
          >
            <MoreVertIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>
      <div className={"content"}>
        <input {...getInputProps()} />
        <PipelineForm
          pipedriveUsers={pipedriveUsers}
          registerFormRef={(ref) => (formRef = ref)}
        />
      </div>

      {isDragActive && (
        <div className="upload-drag-overlay">
          <CloudUploadIcon />
        </div>
      )}
    </div>
  );
}

export default FormView;
